"use client"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { httpLink, loggerLink } from "@trpc/client"
import { createTRPCReact } from "@trpc/react-query"
import { useState } from "react"

import { type AppRouter } from "~/server/routers"
import { getUrl, transformer } from "./shared"

const sessionId = crypto.randomUUID()

export const api = createTRPCReact<AppRouter>()

export function TrpcReactProvider(props: {
    children: React.ReactNode
    cookies: string
}) {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                    },
                },
            }),
    )

    const [trpcClient] = useState(() =>
        api.createClient({
            transformer,
            links: [
                loggerLink({
                    enabled: () =>
                        process.env.NEXT_PUBLIC_ACCESSLY_ENV === "local",
                }),
                // loggerLink({
                //     enabled: (op) =>
                //         process.env.NODE_ENV === "development" ||
                //         (op.direction === "down" && op.result instanceof Error),
                // }),
                httpLink({
                    url: getUrl(),
                    headers() {
                        return {
                            cookie: props.cookies,
                            "x-session-id": sessionId,
                            "x-trpc-source": "react",
                        }
                    },
                }),
                // unstable_httpBatchStreamLink({
                //       url: getUrl(),
                //       headers() {
                //           return {
                //               cookie: props.cookies,
                //               "x-session-id": sessionId,
                //               "x-trpc-source": "react",
                //           }
                //       },
                //   }),
            ],
        }),
    )

    return (
        <QueryClientProvider client={queryClient}>
            <api.Provider client={trpcClient} queryClient={queryClient}>
                {props.children}
            </api.Provider>
        </QueryClientProvider>
    )
}
